import React from 'react';
import Routing from "./pages/Routing";
import 'react-material-symbols/rounded';
import 'react-material-symbols/outlined';
import 'react-material-symbols/sharp';

const App = () => {
  return (
      <>
        <Routing/>
      </>
  );
}

export default App;
