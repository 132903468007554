import React from "react";
import CategoryItem from "./CategoryItem";

import liquorIcon from '../../../assets/icons/liquor.svg';
import beerIcon from '../../../assets/icons/sports_bar.svg';
import wineIcon from '../../../assets/icons/wine_bar.svg';
import groceryIcon from '../../../assets/icons/grocery.svg';

const categories = [
    { icon: liquorIcon, label: "Alko" },
    { icon: beerIcon, label: "Pivo" },
    { icon: wineIcon, label: "Víno" },
    { icon: groceryIcon, label: "Nealko" },

];

interface CategoryListProps {
    handleSearch: (searchTerm: string) => void;
}

const CategoryList: React.FC<CategoryListProps> = ({ handleSearch }) => {
    return (
        <ul className="main-categories">
            {categories.map((category, index) => (
                <CategoryItem
                    key={index}
                    icon={category.icon}
                    label={category.label}
                    onClick={() => handleSearch(category.label)}
                />
            ))}
        </ul>
    );
};

export default CategoryList;