import Header from "../../components/layout/Header/Header";

const AboutPage = () => {
    return (
        <>
            <Header/>
        </>
    )
}

export default AboutPage;
