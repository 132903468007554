import React from "react";
import CategoryList from "./CategoryList";
import SearchForm from "./SearchForm";

interface CategoriesProps {
    handleSearch: (searchTerm: string) => void;
}

const Categories: React.FC<CategoriesProps> = ({ handleSearch }) => {
    return (
        <div className="wrap">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-align-left">
                        <CategoryList handleSearch={handleSearch}/>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-align-left">
                                <SearchForm handleSearch={handleSearch}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Categories;
