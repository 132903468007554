import React from "react";
import SVGIcon from "../../global/SvgIcon";

interface CategoryItemProps {
    icon: string;
    label: string;
    onClick: () => void;
}


// TODO: replacni <a> za <button> pre vyjebanie skurveneho linting warningu
const CategoryItem: React.FC<CategoryItemProps> = ({ icon, label, onClick }) => {
    return (
        <li>
            <a onClick={(e) => {
                e.preventDefault();
                onClick();
            }}>
                <SVGIcon icon={icon} size={24} />
                <span className="label mt-1">{label}</span>
            </a>
        </li>
    );
};

export default CategoryItem;