import { MaterialSymbol } from "react-material-symbols";
import React from "react";

interface Product {
    id: string,
    name: string,
    description: string,
    price: string,
    stock: number,
    imageUrl: string,
}

interface ProductItemProps {
    product: Product;
}

interface StockInfo {
    status: string;
    className: string;
}

export const ProductItem: React.FC<ProductItemProps> = ({ product }) => {
    const stockInfo = getStockInfo(product.stock);

    return (
        <div className="card product-item product-id">
            <a href={`/products/${product.id}`}>
                <div className="thumb">
                    <img src={product.imageUrl} alt="product1"></img>
                </div>
            </a>
            <article>
                <a href={`/products/${product.id}`}>
                    <h2 className="product-label">{product.name}</h2>
                </a>
                <p className="product-data">
                    <span>{product.description}</span>
                </p>
                <div className="product-price">{product.price}</div>
                <div className={`product-info ${stockInfo.className}`}>
                    <MaterialSymbol icon="check_circle" />
                    <span>{stockInfo.status}</span>
                </div>
                <button type="button" className="product-add-card">
                    <div className="icon-animation">
                        <MaterialSymbol icon="shopping_cart" className="cart" />
                        <MaterialSymbol icon="add_circle" className="plus" />
                    </div>
                    Pridať do košíka
                </button>
            </article>
        </div>
    );
}

const getStockInfo = (stock: number): StockInfo => {
    switch (true) {
        case stock === 0:
            return { status: "Nedostupné", className: "unavailable" };
        case stock === 1:
            return { status: "Posledný kus", className: "last" };
        case stock > 5:
            return { status: "Na sklade > 5 ks", className: "instock" };
        default:
            return { status: `Na sklade ${stock} ks`, className: "instock" };
    }
};

export default ProductItem;