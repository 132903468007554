import React from "react";

const FooterLinks: React.FC = () => (
    <>
        <div className="flex-col-6 text-align-left">
            <h1 className="font-uppercase text-h5">Odkazy</h1>
            <ul className="urls">
                <li>
                    <a href="/">Úvod</a>
                </li>
                <li>
                    <a href="/">Ako to funguje</a>
                </li>
                <li>
                    <a href="/">Doručovacie lokality</a>
                </li>
            </ul>
        </div>
        <div className="flex-col-6 text-align-left">
            <ul className="urls">
                <li>
                    <a href="/">Všeobecné obchodné podmienky</a>
                </li>
                <li>
                    <a href="/">Ochrana osobných údajov</a>
                </li>
                <li>
                    <a href="/">Kontakty</a>
                </li>
            </ul>
        </div>
    </>
);

export default FooterLinks;