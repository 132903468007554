import React from "react";

interface SVGIconProps {
  icon: string;
  size?: number;
}

const SVGIcon: React.FC<SVGIconProps> = ({ icon, size = 36 }) => {
  return (
    <img src={icon} alt="icon" width={size} height={size} className="icon" />
  );
};

export default SVGIcon;
