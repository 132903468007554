import React from "react";
import logo from "../../../assets/logo-edrink.png";

const NavBoxLeft: React.FC = () => {
  return (
    <div className="nav-box-left">
      <ul>
        <li>
          <div className="company-logo">
            <a href="./">
              <img src={logo} alt="eDRINK logo" className="company-logo" />
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default NavBoxLeft;
