import React from "react";
import TypedText from "./TypedText";

const Intro: React.FC = () => {
  const typedStrings = ["večierok", "párty", "oslavu", "relax"];

  return (
      <div className="wrap">
        <div className="container">
          <div className="row">
            <div className="col-12 text-align-left">
              <h1 className="margin-bottom-none color-white">
                Hľadám niečo <br />
                na{" "}
                <TypedText strings={typedStrings} />
                .
              </h1>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Intro;
