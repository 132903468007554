import React from "react";
import NavBoxLeft from "./NavBoxLeft";
import NavBoxRight from "./NavBoxRight";
import "./Header.css";
import HeaderBottom from "./HeaderBottom";

const Header: React.FC = () => {
  return (
      <header>
          <nav className="nav-top">
              <div className="wrap">
                  <div className="nav-box">
                      <NavBoxLeft />
                      <NavBoxRight />
                  </div>
              </div>
          </nav>
          <nav className="nav-bottom">
              <div className="wrap">
                  <div className="nav-box">
                      <HeaderBottom />
                  </div>
              </div>
          </nav>
      </header>
  );
};

export default Header;
