import React from "react";
import FooterLogo from "./FooterLogo";
import SocialLinks from "./SocialLinks";
import Disclaimer from "./Disclaimer";
import FooterLinks from "./FooterLinks";
import "./Footer.css";

const Footer: React.FC = () => {
  return (
      <footer>
        <div className="wrap">
          <div className="row">
            <div className="col-12">
              <div className="flex align-items-center">
                <FooterLogo />
                <SocialLinks />
                <Disclaimer />
                <FooterLinks />
              </div>
            </div>
          </div>
        </div>
      </footer>
  );
};

export default Footer;
