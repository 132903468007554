import React from "react";
import { ReactTyped } from "react-typed";

export interface TypedTextProps {
    strings: string[];
    typeSpeed?: number;
    backSpeed?: number;
    loop?: boolean;
    showCursor?: boolean;
    className?: string;
}

const TypedText: React.FC<TypedTextProps> = ({ strings, typeSpeed = 150, backSpeed = 100, loop = true, showCursor = false, className = "color-orange" }) => (
    <ReactTyped
        strings={strings}
        typeSpeed={typeSpeed}
        backSpeed={backSpeed}
        loop={loop}
        showCursor={showCursor}
    >
        <span className={className}></span>
    </ReactTyped>
);

export default TypedText;
