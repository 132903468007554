import React from "react";
import NavItem from "./NavItem";
import shoppingCartIcon from "../../../assets/icons/shopping_cart.svg";

const NavBoxRight: React.FC = () => {
  return (
    <div className="nav-box-right">
      <ul>
        <li>
          <NavItem to="/cart" icon={shoppingCartIcon} size={22}>
            <span id="cartSum">0,00€</span>
          </NavItem>
        </li>
      </ul>
    </div>
  );
};

export default NavBoxRight;
