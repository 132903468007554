import React from "react";

const Disclaimer: React.FC = () => (
    <div className="flex-col-12 text-align-left">
        <p className="margin-bottom-none">
            Obsah tejto webovej stránky je vhodný len pre osoby staršie ako 18 rokov
        </p>
    </div>
);

export default Disclaimer;
