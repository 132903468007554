import React from "react";
import Header from "../../components/layout/Header/Header";
import Main from "../../components/layout/Main/Main";
import Footer from "../../components/layout/Footer/Footer";

const NotFoundPage = () => {
  return (
    <>
      <Header />
      <Main>
        <div className="wrap">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="flex">
                  <div className="flex-col-12">
                    <h1 className="margin-bottom-none color-white">
                      Stránka nebola nájdená
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
      <Footer />
    </>
  );
};

export default NotFoundPage;
