import React from "react";
import NavItem from "./NavItem";
import searchIcon from "../../../assets/icons/search.svg";
import viewCozyIcon from "../../../assets/icons/view_cozy.svg";
import labelIcon from "../../../assets/icons/label.svg";
import personIcon from "../../../assets/icons/person.svg";


const header = [
    { name: "Hladať", nav: "/cart", icon: searchIcon },
    { name: "Kategórie", nav: "/cart", icon: viewCozyIcon },
    { name: "Objednať", nav: "/cart", icon: labelIcon },
    { name: "Kupóny", nav: "/cart", icon: labelIcon},
    { name: "Účet", nav: "/cart", icon: personIcon }
]

const HeaderBottom = () => {
  return (
    <div className="nav-box-menu">
      <ul>
        {header.map((value, index) => (
            <li key={index}>
              <NavItem to={value.nav} icon={value.icon} size={18}>
                <span>{value.name}</span>
              </NavItem>
            </li>
        ))}
      </ul>
    </div>
  );
};

export default HeaderBottom;
