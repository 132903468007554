import React from "react";
import { Link } from "react-router-dom";
import iconFacebook from "../../../assets/social-icons/facebook.png";
import iconInstagram from "../../../assets/social-icons/instagram.png";
import iconWhatsapp from "../../../assets/social-icons/whatsapp.png";
import iconX from "../../../assets/social-icons/twitter.png";

interface SocialItemProps {
    to: string;
    source: string;
    name: string;
}

const SocialItem: React.FC<SocialItemProps> = ({ to, name, source }) => (
    <li>
        <Link to={to}>
            <img src={source} alt={name} />
        </Link>
    </li>
);

const SocialLinks: React.FC = () => (
    <div className="flex-col-8 text-align-left">
        <ul className="social">
            <SocialItem to="/" source={iconFacebook} name="facebook" />
            <SocialItem to="/" source={iconInstagram} name="instagram" />
            <SocialItem to="/" source={iconWhatsapp} name="whatsapp" />
            <SocialItem to="/" source={iconX} name="x" />
        </ul>
    </div>
);

export default SocialLinks;
