import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import HomePage from "./Home/HomePage";
import NotFoundPage from "./NotFoundPage/NotFoundPage";
import AboutPage from "./About/AboutPage";

const Routing = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </Router>
    );
};

export default Routing;