import React, {useState} from "react";
import SVGIcon from "../../global/SvgIcon";
import searchIcon from "../../../assets/icons/search.svg";

interface SearchFormProps {
    handleSearch: (searchTerm: string) => void;
}

const SearchForm: React.FC<SearchFormProps> = ({ handleSearch }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleSearch(searchTerm);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
        handleSearch(newSearchTerm);
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                id="main-search"
                name="main-search"
                placeholder="Hľadať produkty"
                value={searchTerm}
                onChange={handleInputChange}
            />
            <label htmlFor="main-search">
                <SVGIcon icon={searchIcon} />
            </label>
        </form>
    );
};

export default SearchForm;
