import React from "react";
import footerLogo from "../../../assets/logo-edrink.png";

const FooterLogo: React.FC = () => (
    <div className="flex-col-4 text-align-left">
        <div>
            <img className="footer-logo" src={footerLogo} alt="footer logo" />
        </div>
    </div>
);

export default FooterLogo;
