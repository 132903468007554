import React from "react";
import Header from "../../components/layout/Header/Header";
import Main from "../../components/layout/Main/Main";
import Intro from "../../components/sections/Intro/Intro";
import Footer from "../../components/layout/Footer/Footer";
import Categories from "../../components/sections/Categories/Categories";
import useFetchProducts from "../../hooks/useFetchProducts";
import ProductListSection from "../../components/sections/Products/ProductsList";

function HomePage() {
  const { products, loading, error, hasMore, loadMore, handleSearch } = useFetchProducts();

  return (
    <>
      <Header />
      <Main>
        <Intro />
          <Categories handleSearch={handleSearch} />
          <ProductListSection
              products={products}
              loading={loading}
              error={error}
              hasMore={hasMore}
              loadMore={loadMore}
          />
      </Main>
      <Footer />
    </>
  );
}

export default HomePage;
