import React, {ReactNode} from "react";

export interface MainComponentProps {
    children: ReactNode;
}

const Main: React.FC<MainComponentProps> = ({ children }) => {
    return (
        <main>
            {React.Children.map(children, (child, index) => (
                <section key={index}>
                    {child}
                </section>
            ))}
        </main>
    );
};

export default Main;
