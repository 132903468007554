import {Product, ProductsResponse} from "../models/ProductData";
import {useCallback, useEffect, useState} from "react";
import axios from 'axios';

const API_BASE_URL = 'http://localhost:3001/v1';

const useFetchProducts = (initialLimit = 10) => {
    const [products, setProducts] = useState<Product[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [search, setSearch] = useState('');
    const [limit] = useState(initialLimit);

    const fetchProducts = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get<ProductsResponse>(`${API_BASE_URL}/product`, {
                params: { page, limit, search }
            });
            const { data } = response;
            setProducts(prevProducts => page === 1 ? data.products : [...prevProducts, ...data.products]);
            setHasMore(data.metadata.currentPage < data.metadata.totalPages);
            setError(null);
        } catch (error) {
            setError('An error occurred while fetching products');
        } finally {
            setLoading(false);
        }
    }, [page, limit, search]);

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    const loadMore = useCallback(() => {
        if (!loading && hasMore) {
            setPage(prevPage => prevPage + 1);
        }
    }, [loading, hasMore]);

    const handleSearch = useCallback((searchTerm: string) => {
        setSearch(searchTerm);
        setPage(1);
        setProducts([]);
    }, []);

    return { products, loading, error, hasMore, loadMore, handleSearch };
};

export default useFetchProducts;