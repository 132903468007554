import React, { useCallback, useRef } from "react";
import ProductItem from "./ProductItem";
import { Product } from "../../../models/ProductData";
import ProductSpinner from "../../global/ProductLoader/ProductSpinner";

interface ProductListSectionProps {
  products: Product[];
  loading: boolean;
  error: string | null;
  hasMore: boolean;
  loadMore: () => void;
}

const ProductListSection: React.FC<ProductListSectionProps> = ({
  products,
  loading,
  error,
  hasMore,
  loadMore,
}) => {
  const observer = useRef<IntersectionObserver | null>(null);

  const lastProductElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, loadMore]
  );

  if (error) console.log(error);

  return (
    <div className="wrap">
      <div className="container">
        <div className="row">
          <div className="col-12 text-align-left">
            <div className="product-list">
              {products.map((product, index) => (
                <div
                  key={product.id}
                  ref={
                    index === products.length - 1 ? lastProductElementRef : null
                  }
                >
                  <ProductItem product={product} />
                </div>
              ))}
              {loading || (error &&
                  [...Array(4)].map((_, index) => (
                    <div key={index}>
                      <ProductSpinner />
                    </div>
              )))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductListSection;
