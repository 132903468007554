import React from "react";
import { Link } from "react-router-dom";
import SVGIcon from "../../global/SvgIcon";

interface NavItemProps {
  to: string;
  icon: string;
  children?: React.ReactNode;
  size?: number;
}

const NavItem: React.FC<NavItemProps> = ({ to, icon, children, size = 24 }) => (
  <Link to={to}>
    <SVGIcon icon={icon} size={size} />
    {children}
  </Link>
);

export default NavItem;
